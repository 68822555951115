(function ($) {
    //Header Fastbooker
    var d = new Date(new Date().getTime());
    var t = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    var today = d.getDate() + "/" + ('0' + (d.getMonth() + 1)).slice(-2) + "/" + d.getFullYear();
    var tomorrow = t.getDate() + "/" + ('0' + (t.getMonth() + 1)).slice(-2) + "/" + t.getFullYear();

    $('#arrival').val(today);
    $('#departure').val(tomorrow);
		updateCheckin();
		updateCheckout();

    var arrival = $('#arrival');
    var departure = $('#departure');

    arrival.datepicker({
        format: 'dd/mm/yyyy',
        autoclose: true,
        startDate: 'd',
        weekStart: 1,
        orientation: 'bottom'
    }).on('changeDate', function (e) {
        departure.focus();
        var selectedDate = arrival.datepicker('getDate');
        var nextDay = new Date(selectedDate);
        nextDay.setDate(nextDay.getDate() + 1);
				updateCheckin();
        departure.datepicker('setDate', nextDay);
        departure.datepicker('setStartDate', nextDay);
    });

    departure.datepicker({
        format: 'dd/mm/yyyy',
        autoclose: true,
        startDate: tomorrow,
        weekStart: 1,
        orientation: 'bottom'
		}).on('changeDate', function(e){
			updateCheckout();
		});

		function getMonthName(month){
			var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
			month = parseInt(month, 10);
			var monthName = months[month - 1];
			return monthName;
		}

		function updateCheckin(){
			var date = $('#arrival').val();
			date = date.split('/');
			$('.js-checkin-day').text(date[0]);
			var monthName = getMonthName(date[1]);
			if($('.js-checkin-month-year').hasClass('fastbooker__month-year')){
				monthName = monthName.slice(0, 3);
			}
			$('.js-checkin-month-year').text(monthName + ' ' + date[2]);
		}
		
		function updateCheckout(){
			var date = $('#departure').val();
			date = date.split('/');
			$('.js-checkout-day').text(date[0]);
			var monthName = getMonthName(date[1]);
			if($('.js-checkin-month-year').hasClass('fastbooker__month-year')){
				monthName = monthName.slice(0, 3);
			}
			$('.js-checkout-month-year').text(monthName + ' ' + date[2]);
		}

		
var btn = $('.js-fastbooker-btn');

	btn.click(function(e){
		e.preventDefault();
		var arrival = $('#arrival').val();
		arrival = arrival.split('/');
		arrival = arrival[2] + '-' + arrival[1] + '-' + arrival[0];

		var departure = $('#departure').val();
		departure = departure.split('/');
		departure = departure[2] + '-' + departure[1] + '-' + departure[0];

		var href = "https://reservations.cubilis.eu/hof-ter-duinen-hotel-oostduinkerke-aan-zee-koksijde/Rooms/Select?Arrival=" + arrival + "&Departure=" + departure;
		window.open(href, '_blank');
	});

})(jQuery);
