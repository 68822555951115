(function(){
	var formContact = $('#main-contact-form');
	if (formContact.length) {
			formContact.submit(function(e){
					e.preventDefault();
					var targetUrl = Routing.generate('site_frontend_ajax_call');
					var form_status = $( '.form_status' );

					$.ajax({
							method: 'POST',
							url: targetUrl,
							beforeSend: function() {
								form_status.html( '<p><i class="fa fa-spinner fa-spin"></i> <b>Bericht verzenden...</b></p>' ).fadeIn();
							},
							data: {
									form: formContact.serializeArray()
							}
					}).done(function(data){
							if(data.status === "ok"){
									form_status.html( '<p class="text-success"><b>' + data.message + '</b></p>' ).delay( 3000 ).fadeIn();
									//$('.js-success').addClass('showMessage');
									//formContact.hide();
									formContact.trigger('reset');
							}
							if(data.status === "error"){
									//$('.js-error').addClass('showMessage');
									form_status.html( '<p class="text-error"><b>' + data.message + '</b></p>' ).delay( 3000 ).fadeIn();
							}
					});
			});
	}
})();

(function(){
	var formCoupon = $('#coupon-form');
	if (formCoupon.length) {
			formCoupon.submit(function(e){
					e.preventDefault();
					var targetUrl = Routing.generate('site_frontend_ajax_call');
					//var form_status = $('<div class="form_status"></div>');
					var form_status = $( '.form_status' );
					$.ajax({
							method: 'POST',
							url: targetUrl,
							beforeSend: function() {
									//formCoupon.append(form_status.html('<p><i class="fa fa-spinner fa-spin"></i> Bericht verzenden...</p>').fadeIn());
									form_status.html('<p><i class="fa fa-spinner fa-spin"></i> <b>Bericht verzenden...</b></p>').fadeIn();
							},
							data: {
									form: formCoupon.serializeArray()
							}
					}).done(function(data){
							if(data.status === "ok"){
								//form_status.html('<p class="text-success">' + data.message + '</p>').delay(3000).fadeOut();
								form_status.html('<p class="text-success"><b>' + data.message + '</b></p>').delay(3000).fadeIn();
											//formContact.hide();
									formCoupon.trigger('reset');
							}
							if(data.status === "error"){
								//form_status.html('<p class="text-error">' + data.message + '</p>').delay(3000).fadeOut();
								form_status.html('<p class="text-error"><b>' + data.message + '</b></p>').delay(3000).fadeIn();
							}
					});
			});
	}
})();
