require('./plugins/magnific-popup.js');
require('./modules/slider.js');
require('./modules/accordion.js');
require('./modules/maps.js');
require('./plugins/bootstrap-datepicker.js');
require('./modules/fastbooker.js');
require('./modules/header.js');
require('./modules/scroll.js');
require('./modules/reviews.js');
require('./modules/contact.js');
require('./modules/mobilenav.js');

$('.mfp-gallery').each(function () {
    $(this).magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
          enabled: true
      }
    });
});



var worth = $('.js-couponWorth');
var amount = $('.js-couponAmount');
var total = $('.js-calculatedPrice');
$('.js-company-check').hide();

$('.js-formInput').on('change', function(event) {

  var price = worth.val() *  amount.val();
  console.log(price);
  /* Act on the event */
  total.html(numberWithCommas(price));

});

$('.js-mail').on('keyup', function(event) {
  var confirmmail = $('.js-confirmmail').val();
  var mail = $('.js-mail').val();

  if (mail == confirmmail ) {
    console.log("zelfdwe");
    $('.js-email-error').hide();
  } else {
    $('.js-email-error').show();
  }
});

$('.js-confirmmail').on('keyup', function(event) {
  var confirmmail = $('.js-confirmmail').val();
  var mail = $('.js-mail').val();

  if (confirmmail == mail ) {
    $('.js-email-error').hide();
  } else {
    $('.js-email-error').show();
  }
});

$('.js-checkbox').on('click', function(event) {
  event.preventDefault();
  /* Act on the event */
  console.log('Change');
  $(this).toggleClass('isActive');

  if ($(this).hasClass('isActive')){
    console.log('IsChecked');
    $('.js-company-check').show(200);
    $('.js-company-input').attr('required', true);
  } else {
    $('.js-company-check').hide(200);
    $('.js-company-input').attr('required', false);

  }
});

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
